<template>
  <div class="supplier">
    <supplier-menu :active="2" />
    <servers-filter @change="filterChange" />
    <servers-sort @change="sortChange" />
    <div class="data-list">
      <div class="content">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <div class="servers-list-main flex_b">
              <servers-item
                :item="item"
                :yzTaskStausJson="yzTaskStausJson"
                :yzTaskTagsJson="yzTaskTagsJson"
                v-for="item in datalist"
                :key="item.id"
              />
              <div class="not-data" v-if="!datalist.length && !loading">
                无数据
              </div>
              <div class="loading" v-if="loading">
                <i class="el-icon-loading"></i>数据加载中...
              </div>
              <div class="page">
                <el-pagination
                  background
                  :page-size="pageSize"
                  :current-page.sync="pageNo"
                  @current-change="currentChange"
                  :layout="`prev, pager, next${total > 120 ? ',jumper' : ''}`"
                  prev-text="上一页"
                  next-text="下一页"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
            <hot v-if="!isWap" mode="servers" />
          </div>
          <div class="data-list-r">
            <fast-up />
            <ad />
          </div>
        </div>
      </div>
      <div class="content" v-if="isWap">
        <div class="data-list-main flex_b">
          <div class="data-list-l">
            <hot mode="servers" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import supplierMenu from "@/components/servers/supplier-menu";
import serversFilter from "@/components/servers/servers-filter";
import serversSort from "@/components/servers/servers-sort";
import hot from "@/components/servers/hot";
import ad from "@/components/servers/ad";
import fastUp from "@/components/servers/fastUp";
import serversItem from "@/components/servers/servers-item";
export default {
  components: {
    supplierMenu,
    serversFilter,
    serversSort,
    hot,
    ad,
    fastUp,
    serversItem,
  },
  data() {
    return {
      isWap: false,
      pageNo: 1,
      pageSize: 12,
      total: 0,
      datalist: [],
      loading: false,
      yzTaskStausJson: {},
      yzTaskTagsJson: {},
      params: {
        yzTaskCategoryIds: "",
        yzOrderAssignmentType: "",
        budgetPrice: "",
        yzCraftTypes: "",
        yzUserTypes: "",
        yzTaskStatus: "",
        taskNum: "",
        baojiaCount: "",
        overDate: "",
      },
      params2: {
        areaCode: "",
        orderBy: "",
        yzTaskTags: "",
        priceGte: "",
        priceLte: "",
      },
      keyword: "",
    };
  },
  watch: {
    "$store.state.keyword"(newval, oldval) {
      this.pageNo = 1;
      this.keyword = newval;
      this.getData();
    },
  },
  methods: {
    currentChange(val) {
      this.pageNo = val;
      this.getData();
    },
    filterChange(params) {
      this.params = params;
      this.getData();
    },
    sortChange(params) {
      this.params2 = params;
      this.getData();
    },
    getData() {
      this.loading = true;
      this.datalist = [];
      this.$api
        .get("/api/v1/getYzTaskMainList", {
          params: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            title: this.keyword,
            ...this.params,
            ...this.params2,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.list) {
            this.datalist = res.data.list.map((item) => {
              item.yzTaskTags = item.yzTaskTags
                ? item.yzTaskTags.split(",")
                : [];
              // if (item.recService) {
              //   let arr = item.recService.split("|");
              //   item.serviceLogo = arr.length > 2 ? arr[2] : "";
              //   item.corpCode = arr.length > 0 ? arr[0] : "";
              // }
              return item;
            });
          }
          this.total = res.data.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async getJson() {
      //需求状态
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_staus.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzTaskStausJson[item.dictValue] = item.dictLabel;
          });
        });
      //需求标签
      await this.$sapi
        .get("/api/v1/getDictDataList-yz_task_tags.json")
        .then((res) => {
          res.forEach((item) => {
            this.yzTaskTagsJson[item.dictValue] = item.dictLabel;
          });
        });
      this.keyword = this.$route.query.value;
      this.getData();
    },
  },
  mounted() {
    this.isWap = $(window).width() < 1000;
    this.getJson();
    this.$parent.finish ? this.$parent.finish() : null;
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/supplier.scss";
</style>